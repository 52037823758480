import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import {Helmet} from "react-helmet";

const MessageAds = () => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);

    const [adList, setAdList] = useState([])

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        createAdButton: {
            textDecoration: "none"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    useEffect(() => {
        init();
      }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_MESSAGE_ADS_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result === 1){
                    let tempArr = [];
                    response.message_ad_list.forEach(adData => {
                        let tmp = {
                            messageId: adData.message_id,
                            messageName: adData.message_name,
                            messageType: adData.message_type,
                            messageStatus: adData.message_status
                        }
                        tempArr.push(tmp);
                    });
                    setAdList(tempArr);
                }else{
                    navigate('/login');
                }
            },
            error: function(e){
                console.warn(e);
                navigate('/login');
            }
        });
    }

    function getAdsItem(item) { 
        let messageType = "Pesan Teks";
        if(item.messageType === 102){
            messageType = "Pesan Foto";
        }else if(item.messageType === 103){
            messageType = "Pesan Video";
        }
        let messageStatus = "Menunggu Pembayaran";
        if(item.messageStatus === 1){
            messageStatus = "Sedang ditinjau Tim WoiloAds";
        }else if(item.messageStatus === 2){
            messageStatus = "Pesan Sedang Dikirim";
        }else if(item.messageStatus === 3){
            messageStatus = "Pengiriman Pesan Selesai";
        }else if(item.messageStatus === 4){
            messageStatus = "Pesan ditolak";
        }
        return (
            <Link style={{
                textDecoration: "none",
                display: "flex",
                marginTop: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                border: "1px solid "+appContext.colors.primaryGrey,
                borderRadius: "10px"}} 
                
                to={"/dashboard/message_ads/"+item.messageId}>
                <div key={item.messageId}  style={{
                    width: "100%",
                    marginTop: "10px",
                    marginBottom: "10px",
                    color: appContext.colors.primaryBlack,
                    }}>
                    <h4 style={{fontWeight: "400", marginBottom: "0px",}}>{item.messageName}</h4>
                    <label>{messageType} • {messageStatus}</label>
                </div>
            </Link>
        );
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    <div style={styles.mainContainerStyle}>

                        <h1>Kampanye Pesan</h1>
                        <p>Jangkau lebih banyak orang dengan mengirim pesan ke seluruh pengguna aktif di Woilo.</p>
                        <hr></hr>
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={() => {navigate("/dashboard/message_ads/create")}} style={styles.sendButtonStyle}>Buat Pesan Baru</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        <div>
                            {adList.length > 0 && adList.map((item) => getAdsItem(item))}
                        </div>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default MessageAds;