import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';
import axios from 'axios';
import { Label } from "recharts";
import WoiloLogo from '../../asset/logo_long_transparent_cropped.png';

import BriLogo from '../../asset/bankbri_logo.png';
import MandiriLogo from '../../asset/bankmandiri_logo.png';
import BcaLogo from'../../asset/bankbca_logo.png';
import SuccessAlert from '../../asset/success_alert.png';

const PaymentAdPage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [paymentInfo, setPaymentInfo] = useState({
        paymentAmount: 0,
        isPaid: false
    })

    const [briVirtualAccount, setBriVirtualAccount] = useState({
        isAvailable: true,
        number: "",
        message: ""
    })

    const [mandiriVirtualAccount, setMandiriVirtualAccount] = useState({
        isAvailable: true,
        number: "",
        message: ""
    })

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        },

        invoiceContainer: {
            width: (window.innerWidth*4/5 - 60)+"px",
            marginBottom: "10px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        },

        invoiceContainer: {
            width: (window.innerWidth - 60)+"px",
            marginBottom: "10px"
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.AD_PAYMENT_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), ad_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result === 1){
                    setIsLoaded(true);
                    setPaymentInfo({
                        paymentAmount: response.amount,
                        isPaid: response.is_paid === 1,
                    })
                    setBriVirtualAccount({
                        isAvailable: response.bri_va.available === 1,
                        number: response.bri_va.number,
                        message: response.bri_va.message
                    })
                    setMandiriVirtualAccount({
                        isAvailable: response.mandiri_va.available === 1,
                        number: response.mandiri_va.number,
                        message: response.mandiri_va.message
                    })
                }else{
                    navigate('/dashboard/ads/create/confirm/'+params.id);
                }
                setIsLoading(false);
            },
            error: function(e){
                console.warn(e);
                setIsLoading(false);
                navigate('/dashboard/ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

	const onCheckStatus = async() => {
        setIsLoading(true);
        init();
	};

    const onFinish = async() => {
        navigate('/dashboard/ads');
	};

    const onBack = () => {
        navigate('/dashboard/ads/create/invoice/'+params.id);
    }

    const payBca = () => {
        navigate('/dashboard/ads/create/payment/bca/'+params.id);
    }

    function convertToIdr(price){
        if(price === 0){
            return "-";
        }
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(price);
    }

    function getBriPayment(){
        if(!briVirtualAccount.isAvailable){
            return (<></>);
        }
        let logoHeight = "50px";
        if(appContext.mobileMode){
            logoHeight = "25px"
        }
        return (
            <>
                <div style={{border: "2px solid "+appContext.colors.primaryBlack, borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
                    <h5>Virtual Account</h5>
                    <img src={BriLogo} alt="bank_logo" height={logoHeight}/>
                    <h4 style={{marginTop: "5px"}}>{briVirtualAccount.number}</h4>
                    <label>{briVirtualAccount.message}</label>
                </div>
            </>
        );
    }

    function getMandiriPayment(){
        if(!mandiriVirtualAccount.isAvailable){
            return (<></>);
        }
        let logoHeight = "50px";
        if(appContext.mobileMode){
            logoHeight = "25px"
        }
        return (
            <>
                <div style={{border: "2px solid "+appContext.colors.primaryBlack, borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
                    <h5>Virtual Account</h5>
                    <img src={MandiriLogo} alt="bank_logo" height={logoHeight}/>
                    <h4 style={{marginTop: "5px"}}>{mandiriVirtualAccount.number}</h4>
                    <label>{mandiriVirtualAccount.message}</label>
                </div>
            </>
        );
    }

    function getBcaPayment(){
        let logoHeight = "50px";
        if(appContext.mobileMode){
            logoHeight = "25px"
        }
        return (
            <>
                <div style={{border: "2px solid "+appContext.colors.primaryBlack, borderRadius: "10px", padding: "10px", marginTop: "10px"}}>
                    <h5>Transfer Bank</h5>
                    <img src={BcaLogo} alt="bank_logo" height={logoHeight}/>
                    <h4 style={{marginTop: "5px"}}>0888300123 | Karya Digital Indo PT</h4>
                    <label>Transfer dana ke nomor rekening yang tertera di atas, lalu kirim bukti transfer dengan menekan tombol di bawah.</label><br></br>
                    <label>*Jika anda menggunakan metode pembayaran ini, pembayaran akan di proses dalam waktu 1x24 jam setelah anda mengirim bukti transfer.</label>
                    <div style={{marginTop: "10px"}}>
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={payBca} style={styles.sendButtonStyle}>Kirim Bukti Transfer</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                </div>
            </>
        );
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?
                        !paymentInfo.isPaid?<div style={styles.mainContainerStyle}>
                            <h1>Selesaikan Pembayaran Iklan</h1>
                            <hr></hr>
                            
                            <h3 style={{color: appContext.colors.primaryRed}}>Belum dibayar</h3>
                            <h3>Nominal : {convertToIdr(paymentInfo.paymentAmount)}</h3>
                            <h6>Kamu dapat melakukan pembayaran dengan menggunakan metode pembayaran yang tersedia di bawah</h6>

                            {getBriPayment()}
                            {getMandiriPayment()}
                            {getBcaPayment()}

                            <label style={{marginTop: "10px"}}>Sudah melakukan pembayaran?</label>

                            <div style={{marginTop: "10px"}}>
                                <Form>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 12}} style={{display: "flex"}}>
                                            <Button onClick={onCheckStatus} style={styles.sendButtonStyle}>Cek Status Pembayaran</Button>
                                            {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                            {isLoading?<div style={{marginTop: "5px", marginLeft: "10px"}}>Memeriksa...</div>:<></>}
                                        </Col>
                                    </Form.Group>
                                </Form>
                                <Form>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 12}} style={{display: "flex"}}>
                                            <Button  style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}} onClick={onBack}>Kembali</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                        :<div style={styles.mainContainerStyle}>
                            <h1>Selesaikan Pembayaran Iklan</h1>
                            <hr></hr>
                            
                            <h3 style={{color: appContext.colors.primaryBlue}}>Sudah dibayar</h3>
                            <h3>Nominal : {convertToIdr(paymentInfo.paymentAmount)}</h3>

                            <div style={{marginTop: "10px"}}>
                                <Form>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 12}} style={{display: "flex"}}>
                                            <Button onClick={onFinish} style={styles.sendButtonStyle}>Lihat Iklan</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    :<></>
                    
                }
            </div>
        </>
    )
}

export default PaymentAdPage;