import React from "react";
import { useTranslation } from "react-i18next";

const DataPolicy = (props) => {
    
    const styles = props.styles
    const { t } = useTranslation();

    return(
        <>
            <p style={styles.tosContentLastChangedStyle}>{t('termsOfServicePage.lastChanged')}</p>
            <p style={styles.tosContentDescriptionStyle}>
                {t('termsOfServicePage.dataPolicy.description.part1')}
                <br></br><br></br>
                {t('termsOfServicePage.dataPolicy.description.part2')}
            </p>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.title2')}</h3>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle1')}</h4>
            <p style={styles.tosContentDescriptionStyle}>
                {t('termsOfServicePage.dataPolicy.subtitle1Description')}
            </p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle2')}</h4>
            <p style={styles.tosContentDescriptionStyle}>
                {t('termsOfServicePage.dataPolicy.subtitle2Description')}
            </p>
            <ul>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li1.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li1.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li2.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li2.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li3.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li3.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li4.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li4.content')}</ p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li5.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li5.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li6.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li6.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li7.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li7.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li8.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li8.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li9.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li9.content')}</ p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li10.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li10.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle2TermList.li11.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle2TermList.li11.content')}</p>
                </li>
            </ul>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.title3')}</h3>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle3')}</h4>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle4')}</h4>
            <p style={styles.tosContentDescriptionStyle}>
                {t('termsOfServicePage.dataPolicy.subtitle4Description')}
            </p>
            <ul>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle4TermList.li1')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle4TermList.li2')}</p>
                </li>
            </ul>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle5')}</h4>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle5Description.part1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle5Description.part2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle5Description.part3')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle5Description.part4')}</p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle6')}</h4>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6Description')}</p>
            <ul>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6TermList.li1')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6TermList.li1')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6TermList.li1')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6TermList.li1')}</p>
                </li>
            </ul>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6Description2.part1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle6Description2.part2')}</p>
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle7')}</h4>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle7Description')}</p>
            <ul>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li1.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li1.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li2.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li2.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li3.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li3.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li4.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li4.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li5.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li5.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li6.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li6.content')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li7.title')}</strong>{t('termsOfServicePage.dataPolicy.subtitle7TermList.li7.content')}</p>
                </li>
            </ul>

                <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle7Description2')}</p>

            <ul>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList2.li1.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle7TermList2.li1.content')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList2.li2.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle7TermList2.li2.content')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList2.li3.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle7TermList2.li3.content')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList2.li4.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle7TermList2.li4.content')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList2.li5.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle7TermList2.li5.content')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}><strong style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle7TermList2.li6.title')}</strong> {t('termsOfServicePage.dataPolicy.subtitle7TermList2.li6.content')}</p></li>
            </ul>

            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle8')}</h4>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle8Description.part1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle8Description.part2')}</p>
            <ul>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList.li1')}</p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList.li2')}</p>
                </li>
            </ul>

            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle8Description2')}</p>
            
            <ul >
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>
                        <b style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList2.li1.title')}</b> {t('termsOfServicePage.dataPolicy.subtitle8TermList2.li1.content')}
                    </p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>
                        <b style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList2.li2.title')}</b> {t('termsOfServicePage.dataPolicy.subtitle8TermList2.li2.content')}
                    </p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>
                        <b style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList2.li3.title')}</b> {t('termsOfServicePage.dataPolicy.subtitle8TermList2.li3.content')}
                    </p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>
                        <b style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList2.li4.title')}</b> {t('termsOfServicePage.dataPolicy.subtitle8TermList2.li4.content')}
                    </p>
                </li>
                <li style={styles.tosListBulletStyle}>
                    <p style={styles.tosContentDescriptionStyle}>
                        <b style={styles.tosContentBoldStyle}>{t('termsOfServicePage.dataPolicy.subtitle8TermList2.li5.title')}</b> {t('termsOfServicePage.dataPolicy.subtitle8TermList2.li5.content')}
                    </p>
                </li>
            </ul>

            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle9')}</h4>

            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle9Description.part1')}</ p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle9Description.part2')}</p>
            
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle10')}</h4>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle10Description.part1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle10Description.part2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle10Description.part2')}</p>
            
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.title4')}</h4>
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle11')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle11Description')}</p>
            
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle12')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle12Description')}</p>
            
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle13')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle13Description')}</p>
            
            <ul>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle13TermList.li1')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle13TermList.li2')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle13TermList.li3')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle13TermList.li4')}</p></li>
                <li style={styles.tosListBulletStyle}><p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle13TermList.li5')}</p></li>
            </ul>
            
            <h4 style={styles.tosContentSubtitleStyle}>{t('termsOfServicePage.dataPolicy.title5')}</h4>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.title5Description')}</p>
            
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle14')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle14Description.part1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle14Description.part2')}</p>
            
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.dataPolicy.subtitle15')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle15Description.part1')}</p>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.dataPolicy.subtitle15Description.part2')}</p>
            
            <h3 style={styles.tosContentTitleStyle}>{t('termsOfServicePage.changesDisclaimer.title')}</h3>
            
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p1')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p2')}</p>
            <p style={styles.tosContentDescriptionStyle}>{t('termsOfServicePage.changesDisclaimer.content.p3')}</p>
        </>
    )
}

export default DataPolicy;