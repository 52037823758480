import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import { jsPDF } from "jspdf";
import * as html2canvas from 'html2canvas';
import axios from 'axios';
import { Label } from "recharts";
import WoiloLogo from '../../asset/logo_long_transparent_cropped.png';

import BriLogo from '../../asset/bankbri_logo.png';
import MandiriLogo from '../../asset/bankmandiri_logo.png';
import BcaLogo from'../../asset/bankbca_logo.png';
import SuccessAlert from '../../asset/success_alert.png';

const TopupPaymentBcaPage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingMessage, setUploadingMessage] = useState("");

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        },

        invoiceContainer: {
            width: (window.innerWidth*4/5 - 60)+"px",
            marginBottom: "10px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        },

        invoiceContainer: {
            width: (window.innerWidth - 60)+"px",
            marginBottom: "10px"
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.TOPUP_PAYMENT_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result === 1){
                    if(response.is_paid === 0){
                        setIsLoaded(true);
                    }else{
                        navigate('/dashboard/balance/topup/payment/');
                    }
                }else{
                    navigate('/dashboard/balance/topup/payment/');
                }
            },
            error: function(e){
                console.warn(e);
                navigate('/dashboard/balance');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

	const submit = async() => {
        setIsUploading(true);
        setUploadingMessage("Mengunggah Bukti Pembayaran...");
        
        let formData = new FormData();
        formData.append("image_file", selectedImageFile);

        let authParam = "?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("password");
        

        try {
            const response = await axios({
                method: "post",
                url: UrlHouse.UPLOAD_IMAGE_TOPUP+authParam,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                
            });
            
            let decodedResponse = response.data;

            if(decodedResponse.result === 1){

                let imageName = decodedResponse.image_name;
    
                $.ajax({
                    type: "POST",
                    url: UrlHouse.PAYMENT_BCA_TOPUP,
                    data: JSON.stringify({
                        email: localStorage.getItem("email"),
                        password: localStorage.getItem("password"),
                        payment_prove: imageName
                    }),
                    dataType: "json",
                    success: function (response) {
                        if(response.result === 1){
                            navigate('/dashboard/balance/topup/payment');
                        }else if(response.result === 2){
                            alert("Anda telah mengirim bukti pembayaran, mohon menunggu...");
                            navigate('/dashboard/balance/topup/payment');
                        }else{
                            alert("Ups! Terjadi masalah saat mengirim bukti pembayaran. Silahkan coba lagi nanti");
                        }
                    },
                    error: function(e){
                        alert("Ups! Terjadi masalah saat mengirim bukti pembayaran. Silahkan coba lagi nanti");
                    }
                });
            }else{
                alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
            }
        } catch(error) {
            alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
        }
        setIsUploading(false);
	};

    const onBack = () => {
        navigate('/dashboard/balance/topup/payment');
    }

    function convertToIdr(price){
        if(price === 0){
            return "-";
        }
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(price);
    }

    const [selectedImageFile, setSelectedImageFile] = useState();
    const [selectedImage, setSelectedImage] = useState(null);

    const onFileChange = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 10)){
            console.log("size valid")
            setSelectedImageFile(event.target.files[0]);
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }else{
            alert("Ukuran File Terlalu Besar")
        }
	};

    function checkFileSize(file, limitMb){
        var limitByte = limitMb * 1024 * 1024;
        return file.size <= limitByte;
    }

    const hiddenFileInput = React.useRef(null);

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?
                        <div style={styles.mainContainerStyle}>
                            <h1>Kirim Bukti Pembayaran BCA</h1>
                            <hr></hr>

                            <Button onClick={() => {hiddenFileInput.current.click()}}>
                                {selectedImage != null?"Pilih Foto Lain":"Pilih Foto"}
                            </Button>
                            <br></br>
                            <input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="image/png, image/jpeg" type="file" onChange={onFileChange} />
                            {selectedImage != null?<img style={styles.imagePreviewStyle} src={selectedImage} alt="preview" />:<div></div>}
                            <br></br>
                            <label style={{fontWeight: "300", marginTop: "5px"}}>Format Foto yang dapat digunakan adalah JPEG, JPG, dan PNG. Dengan ukuran tidak lebih dari 10 MB</label>
                            
                            <div style={{marginTop: "10px"}}>
                                <Form>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 12}} style={{display: "flex"}}>
                                            <Button onClick={submit} style={styles.sendButtonStyle} disabled={selectedImage == null}>Kirim Bukti Transfer</Button>
                                            {isUploading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                            {isUploading?<div style={{marginTop: "5px", marginLeft: "10px"}}>{uploadingMessage}</div>:<></>}
                                        </Col>
                                    </Form.Group>
                                </Form>
                                <Form>
                                    <Form.Group as={Row} className="mb-3">
                                        <Col sm={{ span: 12}} style={{display: "flex"}}>
                                            <Button  style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}} onClick={onBack}>Kembali</Button>
                                        </Col>
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                    :<></>
                    
                }
            </div>
        </>
    )
}

export default TopupPaymentBcaPage;