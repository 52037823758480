import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import { UserData } from "../../utils/UserData";
import Logo from '../../asset/logo_long_transparent_cropped_white.png';

import "../../spinner.css";

import { useNavigate } from "react-router-dom";

import { Row, Col, Form, Button, Card } from 'react-bootstrap';

import DecorationImage from '../../asset/login_decoration.jpg';

import {Helmet} from "react-helmet";

const RecoverPage = () => {
    const appContext = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            display: "flex"
        },

        decorationStyle: {
            width: "50%",
            height: "100vh",
            backgroundImage: "url("+DecorationImage+")",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            objectFit: "cover",
            boxShadow: "inset 0 0 0 1000px rgba(0, 0, 0, 0.8)",
            color: appContext.colors.primaryWhite,
            padding: window.innerWidth/20+"px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
        },

        decorationTitleStyle: {
            marginLeft: window.innerWidth/20+"px"
        },

        decorationDescriptionStyle: {
            marginLeft: window.innerWidth/20+"px",
            fontWeight: "300"
        },

        loginContainerStyle: {
            width: "50%",
            height: "100vh",
            padding: window.innerWidth/20+"px",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
        },

        inputLabelStyle: {
            color: appContext.colors.primaryBlack
        },

        inputStyle: {
            width:  window.innerWidth/4+"px",
            border: "2px solid",
            borderColor: appContext.colors.primaryBlue,
            borderRadius: "4px",
            color: appContext.colors.primaryBlack,
            padding: "10px",
            marginTop: "5px",
            marginBottom: "5px"
        },

        submitButtonStyle: {
            width:  window.innerWidth/4+"px",
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        profilePictureStyle: {
            width: "50px",
            float: "left",
            borderRadius: "50%",
            alignSelf: "center"
        },

        displayNameContainerStyle: {
            float: "left",
            width: (window.innerWidth - 150)+"px",
            marginRight: "0px",
            marginLeft: "10px"
        },

        displayNameStyle: {
            fontWeight: "500",
            fontSize: "16px",
        },

        userNameStyle: {
            width: (window.innerWidth - 150)+"px",
            fontSize: "13px",
            fontWeight: "400",
            marginLeft: "10px",
            color: appContext.colors.primaryGrey
        },

        postPictureStyle: {
            width: (window.innerWidth - 60)+"px",
            marginTop: "10px",
            borderRadius: "10px"
        },

        likeStyle: {
            width: (window.innerWidth - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            color: appContext.colors.primaryBlue,
            marginTop: "10px",
        },

        captionStyle: {
            width: (window.innerWidth - 60)+"px",
            fontSize: "16px",
            fontWeight: "400",
            color: appContext.colors.primaryBlack,
            marginTop: "10px",
            whiteSpace: "pre-wrap"
        },

        headerContainerStyle: {
            height: "50px",
            padding: "10px",
            backgroundColor: appContext.colors.primaryBlue,
        },

        headerLogoStyle: {
            height: "20px",
            marginTop: "5px",
            marginBottom: "5px",
            float: "left"
        },

        downloadButtonStyle: {
            paddingLeft: "5px",
            paddingRight: "5px",
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "15px",
            fontWeight: "500",
            float: "right",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryWhite,
            borderWidth: "2px"
        },

        downloadButtonStyleSecondary: {
            width: (window.innerWidth - 60)+"px",
            textAlign: "center",
            marginBottom: "30px",
            padding: "10px",
            fontSize: "15px",
            fontWeight: "500",
            textDecoration: "none",
            color: appContext.colors.primaryWhite,
            borderStyle: "solid",
            borderRadius: "5px",
            backgroundColor: appContext.colors.primaryBlue,
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      //$('body').css('background-color','#2E80EB');
      
    }, [])

    const params = useParams();
    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const onDownload = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        ReactGA.initialize("G-3CZYHKHHW6");
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            ReactGA.event({
                category: "download",
                action: "app_store"
            });
            window.open("https://woilo.com/appstore/ios.php?source=202", '_blank').focus();
            return;
        }
        ReactGA.event({
            category: "download",
            action: "play_store"
        });
        window.open("https://woilo.com/appstore/android.php?source=202", '_blank').focus();
    }

    const [inputEmail, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        setErrorMessage("");
        if(target === "input_email"){
            setEmail(value);
        }
    }

    const register = (event) => {
        if(inputEmail.length === 0){
            setErrorMessage("Email harus diisi");
            return;
        }else{
            if(!validateEmail(inputEmail)){
                setErrorMessage("Email tidak valid");
                return;
            }
        }
        setIsLoading(true);
        $.ajax({
            type: "POST",
            url: UrlHouse.RESET_PASSWORD,
            data: JSON.stringify({
                email: inputEmail
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                setIsLoading(false);
                if(response.result === 1){
                    alert("Kami telah mengirim email pemulihan akun ke email ini.");
                    navigate('/login');
                }else{
                    setErrorMessage("Akun tidak ditemukan atau tidak terdaftar");
                }
            },
            error: function(e){
                setIsLoading(false);
                setErrorMessage("Ups, terjadi kesalahan. Coba lagi nanti")
            }
        });
    };

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    !appContext.mobileMode?<div style={styles.mainContainerStyle}>
                        <div style={styles.decorationStyle}>
                            <img src={Logo} style={styles.decorationTitleStyle} width="200px" alt="logo"/>
                            <h1 style={styles.decorationTitleStyle}>
                                Jangkau Potensi Bisnismu lewat WoiloAds
                            </h1>
                            <h5 style={styles.decorationDescriptionStyle}>
                                Bangun bisnismu bersama WoiloAds, akses potensi pelanggan kamu dari seluruh Indonesia.
                            </h5>
                        </div>
                        <div style={styles.loginContainerStyle}>
                            <h1>Lupa Password</h1>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Masukkan Email Akun Iklan Kamu</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_email" type="email" placeholder="ads@woilo.com" values={inputEmail}/>
                                </Form.Group>
                                {errorMessage.length > 0?<label style={{marginBottom: "10px", color: "red"}}>{errorMessage}</label>:<></>}
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={register} disabled={inputEmail.length === 0 || errorMessage.length > 0} style={styles.sendButtonStyle}>Atur Ulang Password</Button>
                                        {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                    :
                    <div style={styles.mainContainerStyle}>
                        <div style={styles.loginContainerStyle}>
                            <h1>Lupa Password</h1>
                            <Form>
                                <Form.Group className="mb-3">
                                    <Form.Label>Masukkan Email Akun Iklan Kamu</Form.Label>
                                    <Form.Control onChange={onFormChange} name="input_email" type="email" placeholder="ads@woilo.com" values={inputEmail}/>
                                </Form.Group>
                                {errorMessage.length > 0?<label style={{marginBottom: "10px", color: "red"}}>{errorMessage}</label>:<></>}
                                <Form.Group as={Row} className="mb-3">
                                    <Col sm={{ span: 12}} style={{display: "flex"}}>
                                        <Button onClick={register} disabled={inputEmail.length === 0 || errorMessage.length > 0} style={styles.sendButtonStyle}>Atur Ulang Password</Button>
                                        {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    </Col>
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default RecoverPage;