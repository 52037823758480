import logo from './logo.svg';
import './App.css';
import { useState, useContext, useEffect } from 'react';
import { BrowserRouter, Routes, Route, HashRouter, useNavigate } from 'react-router-dom';
import { AppContext } from './utils/AppContext';

import LandingPage from './pages/LandingPage';
import MainPage from './pages/MainPage';
import TermOfServiePage from './pages/TermOfServicePage';
import ContactUsPage from './pages/ContactUsPage';

import UserDeepLink from './pages/deeplinks/User';
import PostDeeplink from './pages/deeplinks/Post';

import NewsContentPage from './pages/news/NewsContentPage';
import NewsPage from './pages/news/NewsPage';
import NewsCategoryPage from './pages/news/NewsCategoryPage';

import LoginPage from './pages/login/LoginPage';
import RegisterPage from './pages/login/RegisterPage';
import RecoverPage from './pages/login/RecoverPage';
import VerifyEmailPage from './pages/login/VerifyEmailPage';
import VerifyingEmailPage from './pages/login/VerifyingEmailPage';
import ConnectWoiloPage from './pages/login/ConnectWoiloPage';

import DashboardPage from './pages/DashboardPage';
import DashboardBalance from './pages/dashboard/Balance';
import DashboardAccount from './pages/dashboard/Account';
import DashboardHelp from './pages/dashboard/Help';
import DashboardAds from './pages/dashboard/Ads';
import MessageAds from './pages/dashboard/MessageAds';
import DashboardPassword from './pages/dashboard/Password';

import TopupPage from './pages/topup_balance/TopupPage';
import PaymentTopupPage from './pages/topup_balance/PaymentTopupPage';
import TopupPaymentBcaPage from './pages/topup_balance/TopupPaymentBcaPage';

import AdDetailPage from './pages/dashboard/ads/AdDetailPage';

import CreateAdPage from './pages/create_ad/CreateAdPage';
import SetupAdPage from './pages/create_ad/SetupAdPage';
import FinishAdPage from './pages/create_ad/FinishAdPage';
import ConfirmAdPage from './pages/create_ad/ConfirmAdPage';
import InvoiceAdPage from './pages/create_ad/InvoiceAdPage';
import PaymentAdPage from './pages/create_ad/PaymentAdPage';
import PaymentBcaPage from './pages/create_ad/PaymentBcaPage';

import MessageAdDetailPage from './pages/dashboard/message_ads/MessageAdDetailPage';

import CreateMessagePage from './pages/create_message/CreateMessagePage';
import SetupMessagePage from './pages/create_message/SetupMessagePage';
import FinishMessagePage from './pages/create_message/FinishMessagePage';
import ConfirmMessagePage from './pages/create_message/ConfirmMessagePage';
import InvoiceMessagePage from './pages/create_message/InvoiceMessagePage';
import PaymentMessagePage from './pages/create_message/PaymentMessagePage';
import MessagePaymentBcaPage from './pages/create_message/MessagePaymentBcaPage';

import DataPolicy from './sections/tos/DataPolicy';
import UsageGuidelines from './sections/tos/UsageGuidelines';
import AdvertisingTerms from './sections/tos/AdvertisingTerms';
import PrivateAccount from './sections/tos/PrivateAccount';
import VerifiedAccount from './sections/tos/VerifiedAccount';
import WoiloCoin from './sections/tos/WoiloCoin';
import WoiloWallet from './sections/tos/WoiloWallet';
import PopularityEvent from './sections/tos/PopularityEvent';
import NftTerms from './sections/tos/NftTerms';


function App() {

  const [ tosActivePage, setTosActivePage ] = useState(0);
  const appContext = useContext(AppContext);

  const onTosPageChange = (page) => {
    setTosActivePage(page);
  }

  useEffect(() => {
    //document.title = "Woilo - Find New Friends";
    let isDarkMode = localStorage.getItem('isDarkMode');
    if( isDarkMode === null){
      localStorage.setItem('isDarkMode',false)
    }else{
      appContext.setIsDarkMode(JSON.parse(isDarkMode));
    }

    let path = window.location.pathname;

    if(path === '/term.html' || path === '/en/term.html'){
      window.location = ('/tos');
    }
  }, [])

  /*
<Route path="/" element={<LandingPage/>}>
        <Route index element={<MainPage/>}/>
        <Route path="tos" element={<TermOfServiePage activePage={tosActivePage} onPageChange={onTosPageChange}/>}>
          <Route index element={<DataPolicy styles={appContext.tosStyles}/>}/>
          <Route path="usage_guidelines" element={<UsageGuidelines styles={appContext.tosStyles}/>}/>
          <Route path="private_account" element={<PrivateAccount styles={appContext.tosStyles}/>}/>
          <Route path="verified_account" element={<VerifiedAccount styles={appContext.tosStyles}/>}/>
          <Route path="woilo_coin" element={<WoiloCoin styles={appContext.tosStyles}/>}/>
          <Route path="woilo_wallet" element={<WoiloWallet styles={appContext.tosStyles}/>}/>
          <Route path="popularity_event" element={<PopularityEvent styles={appContext.tosStyles}/>}/>
          <Route path="nft_terms" element={<NftTerms styles={appContext.tosStyles}/>}/>
        </Route>
        <Route path="contact" element={<ContactUsPage/>}/>
        <Route path="download" element={<MainPage/>}/>
        <Route path="nft/:nft_id" element={<MainPage/>} />

      </Route>
  */

  return (
    <Routes>
      
      <Route path="tos" element={<TermOfServiePage activePage={tosActivePage} onPageChange={onTosPageChange}/>}>
        <Route index element={<DataPolicy styles={appContext.tosStyles}/>}/>
        <Route path="usage_guidelines" element={<UsageGuidelines styles={appContext.tosStyles}/>}/>
        <Route path="advertising_terms" element={<AdvertisingTerms styles={appContext.tosStyles}/>}/>
      </Route>

      <Route index element={<LoginPage/>}/>
      <Route path="/" element={<LoginPage/>}/>
      <Route path='/login' element={<LoginPage/>}/>
      <Route path='/register' element={<RegisterPage/>}/>
      <Route path='/recover' element={<RecoverPage/>}/>
      <Route path='/verify' element={<VerifyEmailPage/>}/>
      <Route path='/verify/:email/:verify_token' element={<VerifyingEmailPage/>}/>
      <Route path='/connect/woilo' element={<ConnectWoiloPage/>}/>

      <Route path='/dashboard' element={<DashboardPage/>}>
        <Route index element={<DashboardAds/>}/>
        <Route path="ads" element={<DashboardAds/>}/>
        <Route path="message_ads" element={<MessageAds/>}/>
        <Route path="balance" element={<DashboardBalance/>}/>
        <Route path="account" element={<DashboardAccount/>}/>
        <Route path="help" element={<DashboardHelp/>}/>
        <Route path="account/password" element={<DashboardPassword/>}/>
      </Route>

      <Route path="dashboard/balance/topup" element={<TopupPage/>}/>
      <Route path="dashboard/balance/topup/payment" element={<PaymentTopupPage/>}/>
      <Route path="dashboard/balance/topup/payment/bca" element={<TopupPaymentBcaPage/>}/>

      <Route path="dashboard/ads/:id" element={<AdDetailPage/>}/>
      <Route path="dashboard/ads/create" element={<CreateAdPage/>}/>
      <Route path="dashboard/ads/create/setup/:id" element={<SetupAdPage/>}/>
      <Route path="dashboard/ads/create/finish/:id" element={<FinishAdPage/>}/>
      <Route path="dashboard/ads/create/confirm/:id" element={<ConfirmAdPage/>}/>
      <Route path="dashboard/ads/create/invoice/:id" element={<InvoiceAdPage/>}/>
      <Route path="dashboard/ads/create/payment/:id" element={<PaymentAdPage/>}/>
      <Route path="dashboard/ads/create/payment/bca/:id" element={<PaymentBcaPage/>}/>

      <Route path="dashboard/message_ads/:id" element={<MessageAdDetailPage/>}/>
      <Route path="dashboard/message_ads/create" element={<CreateMessagePage/>}/>
      <Route path="dashboard/message_ads/create/setup/:id" element={<SetupMessagePage/>}/>
      <Route path="dashboard/message_ads/create/finish/:id" element={<FinishMessagePage/>}/>
      <Route path="dashboard/message_ads/create/confirm/:id" element={<ConfirmMessagePage/>}/>
      <Route path="dashboard/message_ads/create/invoice/:id" element={<InvoiceMessagePage/>}/>
      <Route path="dashboard/message_ads/create/payment/:id" element={<PaymentMessagePage/>}/>
      <Route path="dashboard/message_ads/create/payment/bca/:id" element={<MessagePaymentBcaPage/>}/>

    </Routes>
  );
}

export default App;
