import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../../utils/UrlHouse";
import DefaultProfile from '../../../asset/default_profile.png';
import VerifiedBadge from '../../../asset/verified_badge.png';
import { AppContext } from "../../../utils/AppContext";
import Logo from '../../../asset/logo_long_transparent_cropped.png';
import "../../../spinner.css";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import {Helmet} from "react-helmet";

const MessageAdDetailPage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [messageData, setMessageData] = useState({
        messageName: "Test Name",
        messageType: 101,
        messageStatus: 2,
        startDate: "03 - 12 - 2022",
        deliveryTarget: 1000,
        deliveredCount: 100,
        messageBudget: 0,
        messageSpending: 0,
        messageBudgetRemaining: 0,
    })

    const [messageMedia, setMessageMedia] = useState({
        advertiserName: "WoiloAds",
        advertiserPicture: "",
        messageType: 101,
        messageText: "This is test Message",
        messageImage: "",
        messageVideo: ""
    })

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        subContainerStyle: {
            width: "100%",
            display: "flex"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            width: "280px"
        },

        sendButtonStyle: {
            
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        subContainerStyle: {
            width: "100%"
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            width: "280px"
        },

        sendButtonStyle: {
            
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_MESSAGE_AD_DETAIL_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result === 1){
                    setIsLoaded(true);
                    
                    setMessageData({
                        messageName: response.message_name,
                        messageType: response.message_type,
                        messageStatus: response.message_status,
                        startDate: response.start_date,
                        deliveryTarget: response.delivery_target,
                        deliveredCount: response.delivered_count,
                        messageBudget: response.message_budget,
                        messageSpending: response.message_spending,
                        messageBudgetRemaining: response.message_budget_remaining,
                    });
                    
                    setMessageMedia({
                        advertiserName: response.advertiser_name,
                        advertiserPicture: response.advertiser_picture,
                        messageType: response.message_type,
                        messageText: response.message_text,
                        messageImage: response.message_image,
                        messageVideo: response.message_video,
                    });

                }else{
                    navigate('/dashboard/message_ads/create/confirm/'+params.id);
                }
            },
            error: function(e){
                console.warn(e);
                navigate('/dashboard/message_ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    function getMessageType(){
        if(messageData.messageType === 101){
            return "Pesan Teks";
        }
        if(messageData.messageType === 102){
            return "Pesan Foto";
        }
        if(messageData.messageType === 103){
            return "Pesan Video";
        }
        return "Pesan Teks";
    }

    function getAdBudget(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(messageData.messageBudget);
    }

    function getAdSpending(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(messageData.messageSpending);
    }

    function getAdBudgetRemaining(){
        const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
        });
        return formatter.format(messageData.messageBudgetRemaining);
    }

    function getMessageStatus(){
        //ad_status
        //0 = waiting for payment
        //1 = waiting for review
        //2 = ad running
        //3 = ad finished
        if(messageData.messageStatus === 0){
            return (
                <div>
                    <h6 style={{color: appContext.colors.primaryGrey}}>Menunggu Pembayaran</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {navigate("/dashboard/message_ads/create/invoice/"+params.id)}} style={styles.sendButtonStyle}>Selesaikan Pembayaran</Button>
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }else if(messageData.messageStatus === 1){
            return (
                <h6 style={{color: appContext.colors.primaryGrey}}>Sedang ditinjau Tim WoiloAds</h6>
            );
        }else if(messageData.messageStatus === 2){
            let progressPercent = (100*messageData.deliveredCount/messageData.deliveryTarget);
            return (
                <div>
                    <h6 style={{color: "green"}}>Pesan Sedang Dikirim</h6>
                    <appContext.progressBar progress={progressPercent}  height={25} />
                    <h6 style={{color: appContext.colors.primaryGrey}}>Terkirim ke {messageData.deliveredCount} pengguna</h6>
                    <br></br>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {stopAd()}} disabled={isLoading} style={{backgroundColor: "red", borderColor: "red"}}>Berhentikan Pengiriman</Button>
                                {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }else if(messageData.messageStatus === 3){
            return (
                <div>
                    <h6 style={{color: "red"}}>Pengiriman Pesan Selesai</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {deleteAd()}} disabled={isLoading} style={{backgroundColor: "white", borderColor: "red", color: "red"}}>Hapus Pesan</Button>
                                {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }else if(messageData.messageStatus === 4){
            return (
                <div>
                    <h6 style={{color: "red"}}>Pesan Ditolak</h6>
                    <Form>
                        <Form.Group as={Row} className="mb-3">
                            <Col sm={{ span: 12}} style={{display: "flex"}}>
                                <Button onClick={() => {deleteAd()}} disabled={isLoading} style={{backgroundColor: "white", borderColor: "red", color: "red"}}>Hapus Pesan</Button>
                                {isLoading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                            </Col>
                        </Form.Group>
                    </Form>
                </div>
            );
        }
        return (<></>);
    }

    function stopAd(){
        confirmAlert({
            title: 'Berhentikan Kampanye Pesan?',
            message: 'Kamu yakin ingin menghentikan Kampanye Pesan ini?',
            buttons: [
                {
                    label: 'Hentikan Kampanye Pesan',
                    onClick: () => {
                        setIsLoading(true);
                        $.ajax({
                            type: "POST",
                            url: UrlHouse.DASHBOARD_STOP_MESSAGE,
                            data: JSON.stringify({
                                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_id: params.id
                            }),
                            dataType: "json",
                            success: function (response) {
                                console.log(response);
                                setIsLoading(false);
                                if(response.result === 1){
                                    alert("Kampanye Pesan berhasil dihentikan")
                                    init();
                                }else{
                                    alert("Ups, terjadi kesalahan. Coba lagi nanti")
                                }
                            },
                            error: function(e){
                                console.warn(e);
                                setIsLoading(false);
                                alert("Ups, terjadi kesalahan. Coba lagi nanti")
                            }
                        });
                    }
                },
                {
                    label: 'Batalkan',
                    onClick: () => {}
                }
            ]
        });
    }

    function deleteAd(){
        confirmAlert({
            title: 'Hapus Kampanye Pesan?',
            message: 'Kamu yakin ingin menghapus Kampanye Pesan ini?',
            buttons: [
                {
                    label: 'Hapus Kampanye Pesan',
                    onClick: () => {
                        setIsLoading(true);
                        $.ajax({
                            type: "POST",
                            url: UrlHouse.DASHBOARD_DELETE_MESSAGE,
                            data: JSON.stringify({
                                email: localStorage.getItem("email"), password: localStorage.getItem("password"), message_id: params.id
                            }),
                            dataType: "json",
                            success: function (response) {
                                console.log(response);
                                setIsLoading(false);
                                if(response.result === 1){
                                    alert("Kampanye Pesan berhasil dihapus")
                                    navigate('/dashboard/message_ads');
                                }else{
                                    alert("Ups, terjadi kesalahan. Coba lagi nanti")
                                }
                            },
                            error: function(e){
                                console.warn(e);
                                setIsLoading(false);
                                alert("Ups, terjadi kesalahan. Coba lagi nanti")
                            }
                        });
                    }
                },
                {
                    label: 'Batalkan',
                    onClick: () => {}
                }
            ]
        });
    }

    const onBack = () => {
        navigate('/dashboard/message_ads')
    }

    function getMessagePreview(){
        
        const containerStyle = {
            width: "400px",
            borderRadius: "5px",
            borderColor: appContext.colors.primaryGrey,
            borderStyle: "solid",
            borderWidth: "1px"
        };
        const displayPictureStyle = {
            width: "50px",
            borderRadius: "50%",
            alignSelf: "center",
            borderColor: appContext.colors.primaryGrey,
            borderStyle: "solid",
            borderWidth: "0.5px"
        };
        const displayNameStyle = {
            marginLeft: "10px",
            marginTop: "10px",
            size: "20px"
        }
        const messageBubble = {
            backgroundColor: appContext.colors.primaryBlue,
            width: "300px",
            margin: "10px",
            paddingLeft: "10px",
            paddingTop: "10px",
            paddingRight: "10px",
            paddingBottom: "10px",
            fontWeight: "400",
            borderRadius: "10px",
            color: appContext.colors.primaryWhite
        }
        //return (<></>);
        if(messageMedia.messageType === 101){
            return (
                <>
                    <div style={containerStyle}>
                        <div style={{display: "flex", paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px"}}>
                            <img style={displayPictureStyle} src={messageMedia.advertiserPicture} alt="profilePicture"/>
                            <h4 style={displayNameStyle}>{messageMedia.advertiserName}</h4>
                        </div>
                        <hr></hr>
                        <div id="text_message_preview" style={messageBubble} dangerouslySetInnerHTML={{__html: messageMedia.messageText.replaceAll('\n','<br/>')}}></div>
                        
                    </div>
                </>
            )
        }
        if(messageMedia.messageType === 102){
            return (
                <>
                    <div style={containerStyle}>
                        <div style={{display: "flex", paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px"}}>
                            <img style={displayPictureStyle} src={messageMedia.advertiserPicture} alt="profilePicture"/>
                            <h4 style={displayNameStyle}>{messageMedia.advertiserName}</h4>
                        </div>
                        <hr></hr>
                        <div style={messageBubble}>
                            <img style={styles.imagePreviewStyle} src={messageMedia.messageImage} alt="preview" />
                        </div>
                        <div id="text_message_preview" style={messageBubble} dangerouslySetInnerHTML={{__html: messageMedia.messageText.replaceAll('\n','<br/>')}}></div>
                    </div>
                </>
            )
        }
        if(messageMedia.messageType === 103){
            return (
                <>
                    <div style={containerStyle}>
                        <div style={{display: "flex", paddingLeft: "10px", paddingTop: "10px", paddingRight: "10px"}}>
                            <img style={displayPictureStyle} src={messageMedia.advertiserPicture} alt="profilePicture"/>
                            <h4 style={displayNameStyle}>{messageMedia.advertiserName}</h4>
                        </div>
                        <hr></hr>
                        <div style={messageBubble}>
                            {
                                <video style={styles.imagePreviewStyle} playsInline controls>
                                    <source src={messageMedia.messageVideo} type="video/mp4"/>
                                </video>
                            }
                        </div>
                        <div id="text_message_preview" style={messageBubble} dangerouslySetInnerHTML={{__html: messageMedia.messageText.replaceAll('\n','<br/>')}}></div>
                    </div>
                </>
            )
        }
        return (
            <></>
        )
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?
                        appContext.mobileMode?
                        <div style={styles.mainContainerStyle}>
                            <div>
                                <div>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3">
                                            <Col sm={{ span: 12}}>
                                                <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}>Kembali</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div>
                                    <h1>{messageData.messageName}</h1>
                                    <label>{getMessageType()}</label>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={styles.subContainerStyle}>
                                <div style={{marginTop: "10px"}}>
                                    <h3>Pengaturan Pesan</h3>
                                    <h5>Status Pesan</h5>
                                    {getMessageStatus()}
                                    <h5>Tanggal Mulai Pengiriman</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{messageData.startDate}</h6>
                                    <h5>Target Pengguna</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{messageData.deliveryTarget} Pengguna</h6>
                                </div>
                                <div style={{marginTop: "10px"}}>
                                    <h3>Biaya Iklan</h3>
                                    <h5>Dana Iklan</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudget()}</h6>
                                    <h5>Dana Terpakai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdSpending()}</h6>
                                    <h5>Dana Tersisa</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudgetRemaining()}</h6>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", width: "100%"}}>
                                <h3>Tampilan Pesan</h3>
                                {getMessagePreview()}
                            </div>
                        </div>
                        :
                        <div style={styles.mainContainerStyle}>
                            <div>
                                <div>
                                    <Form>
                                        <Form.Group as={Row} className="mb-3">
                                            <Col sm={{ span: 12}}>
                                                <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}>Kembali</Button>
                                            </Col>
                                        </Form.Group>
                                    </Form>
                                </div>
                                <div>
                                    <h1>{messageData.messageName}</h1>
                                    <label>{getMessageType()}</label>
                                </div>
                            </div>
                            <hr></hr>
                            <div style={styles.subContainerStyle}>
                                <div style={{flex: "1", float: "left", marginRight: "10px", marginTop: "10px"}}>
                                    <h3>Pengaturan Pesan</h3>
                                    <h5>Status Pesan</h5>
                                    {getMessageStatus()}
                                    <h5>Tanggal Mulai Pengiriman</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{messageData.startDate}</h6>
                                    <h5>Target Pengguna</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{messageData.deliveryTarget} Pengguna</h6>
                                </div>
                                <div style={{flex: "1", float: "left", marginTop: "10px"}}>
                                    <h3>Biaya Iklan</h3>
                                    <h5>Dana Iklan</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudget()}</h6>
                                    <h5>Dana Terpakai</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdSpending()}</h6>
                                    <h5>Dana Tersisa</h5>
                                    <h6 style={{color: appContext.colors.primaryGrey}}>{getAdBudgetRemaining()}</h6>
                                </div>
                            </div>
                            <div style={{marginTop: "20px", width: "100%"}}>
                                <h3>Tampilan Pesan</h3>
                                {getMessagePreview()}
                            </div>
                        </div>
                    :
                    <></>
                    
                }
            </div>
        </>
    )
}

export default MessageAdDetailPage;