import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner , Form, Col, Row, Button} from "react-bootstrap";
import $ from 'jquery';
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import {Helmet} from "react-helmet";

const Help = () => {
    const appContext = useContext(AppContext);

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        sectionContainerStyle: {
            display: "flex",
            paddingTop: "30px",
            paddingBottom: "30px",
        },

        sectionStyle: {
            flex: "1"
        },

        sectionTitleStyle: {
            marginLeft: "15px"
        },

        sectionSubtitleStyle: {
            marginLeft: "15px",
            marginRight: "80px",
            marginBottom: "10px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const contact = (e) => {
        e.preventDefault();
        window.open("mailto:ads@woilo.com", '_blank').focus();
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    <div style={styles.mainContainerStyle}>

                        <h1>Bantuan</h1>
                        <hr></hr>

                        <h4>Bagaimana Cara Membuat Iklan?</h4>
                        <p style={{fontWeight: "400", fontSize: "20px"}}>Kamu dapat membuat iklan dengan membuka halaman "Iklan Saya", lalu klik "Buat Iklan Baru".</p>
                        
                        <h4>Ingin Berbicara Dengan Tim WoiloAds?</h4>
                        <p style={{fontWeight: "400", fontSize: "20px"}}>Hubungi email kami di ads@woilo.com, atau chat langsung dengan @woiloads(bercentang biru) di Woilo.</p>

                    </div>
                    
                }
            </div>
        </>
    )
}

export default Help;