import React, { useContext } from "react";
import { Nav, Row, Col } from "react-bootstrap";
import { Outlet, Link, useParams } from "react-router-dom";
import { AppContext } from "../utils/AppContext";
import { useTranslation } from "react-i18next";

const TermsOfServicePage = (props) => {
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    const desktopStyles = {
        contentContainerStyle: {
            padding: "50px",
            display: "flex"
        },

        tosNavigationStyle: {
            textDecoration: "none",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlue
        },

        tosNavigationStyleInActive: {
            textDecoration: "none",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlack
        },

        tosContentContainerStyle: {
            marginLeft: "100px",
            width: "75%"
        },     
    }

    const mobileStyles = {
        contentContainerStyle: {
            padding: "20px 30px",
            display: "flex",
            flexDirection: "column"
        },

        tosNavigationStyle: {
            textDecoration: "none",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlue
        },

        tosNavigationStyleInActive: {
            textDecoration: "none",
            fontWeight: "400",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlack
        },

        tosContentContainerStyle: {
            width: "100%"
        },     
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles;

    return(
        <>
            <div style={styles.contentContainerStyle}>
                <Nav className="flex-column">
                    <Link onClick={() => {props.onPageChange(0)}} style={props.activePage === 0 ? styles.tosNavigationStyle:styles.tosNavigationStyleInActive} to="/tos" href="/tos">{t('termsOfServicePage.dataPolicy.title')}</Link>
                    <Link onClick={() => {props.onPageChange(1)}} style={props.activePage === 1 ? styles.tosNavigationStyle:styles.tosNavigationStyleInActive} to="usage_guidelines" href="usage_guidelines">{t('termsOfServicePage.usageGuidelines.title')}</Link>
                    <Link onClick={() => {props.onPageChange(2)}} style={props.activePage === 2 ? styles.tosNavigationStyle:styles.tosNavigationStyleInActive} to="advertising_terms" href="advertising_terms">Ketentuan Layanan Beriklan</Link>
                </Nav>
                {
                    appContext.mobileMode ? 
                    <>
                        <hr></hr>
                    </>:
                    <>
                    </>
                }
                <div style={styles.tosContentContainerStyle}>
                    <Outlet/>
                </div>
            </div>
        </>
    )
}

export default TermsOfServicePage;