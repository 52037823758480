import { useEffect, useState } from "react";

const useCheckMobileScreen = () => {
  const twindow = typeof window !== 'undefined' ? window : {};
  
  const [width, setWidth] = useState(twindow.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(twindow.innerWidth);
  }

  useEffect(() => {
    twindow.addEventListener("resize", handleWindowSizeChange);

    return () => {
      twindow.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (width <= 768);
}

export default useCheckMobileScreen;