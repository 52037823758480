import React, { useEffect, useState , useContext} from "react";
import { Row, Col, Form, Button, Card } from 'react-bootstrap';
import { Link} from "react-router-dom";
import { Spinner } from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';
import "../../spinner.css";

import {Helmet} from "react-helmet";
import axios from 'axios';
import { Label } from "recharts";

const SetupAdPage = () => {

    const appContext = useContext(AppContext);
    const navigate = useNavigate();
    const params = useParams();
    const [isLoaded, setIsLoaded] = useState(false);
    const [adType, setAdType] = useState(101);
    const [adName, setAdName] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [uploadingMessage, setUploadingMessage] = useState("");

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px"
        },

        selectImageButtonStyle: {
            display: "none"
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        selectImageButtonStyle: {
            display: 'none'
        },

        imagePreviewStyle: {
            height: "30vh",
            margin: "10px"
        },

        sendButtonStyle: {
            
        }
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    useEffect(() => {
      init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.SETUP_AD_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password"), ad_id: params.id
            }),
            dataType: "json",
            success: function (response) {
                if(response.result === 1){
                    setIsLoaded(true);
                    setAdType(response.ad_type);
                    setAdName(response.ad_name);
                }else if(response.result === 2){
                    navigate('/dashboard/ads/create/finish/'+params.id);
                }else{
                    navigate('/dashboard/ads');
                }
            },
            error: function(e){
                navigate('/dashboard/ads');
            }
        });
    }

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const [selectedImageFile, setSelectedImageFile] = useState();
    const [selectedImage, setSelectedImage] = useState(null);

    const [selectedVideoFile, setSelectedVideoFile] = useState();
    const [selectedVideo, setSelectedVideo] = useState(null);

    const [selectedCarouselFiles, setSelectedCarouselFiles] = useState([]);
    const [selectedCarousels, setSelectedCarousels] = useState([]);

	const [isFilePicked, setIsFilePicked] = useState(false);

    const onFileChange = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 10)){
            console.log("size valid")
            setSelectedImageFile(event.target.files[0]);
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
        }else{
            alert("Ukuran File Terlalu Besar")
        }
	};

    function checkFileSize(file, limitMb){
        var limitByte = limitMb * 1024 * 1024;
        return file.size <= limitByte;
    }

    const onFileChangeVideo = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 25)){
            console.log("size valid")
            setSelectedVideoFile(event.target.files[0]);
            setSelectedVideo(URL.createObjectURL(event.target.files[0]));
        }else{
            alert("Ukuran File Terlalu Besar")
        }
    }

    const onFileChangeCarousel = (event) => {
        var file = event.target.files[0];
        if(checkFileSize(file, 10)){
            var image = new Image();
            image.onload = function () {
                if(this.width === this.height){
                    let tmpFiles = selectedCarouselFiles;
                    let tmpCarousels = selectedCarousels;
            
                    tmpFiles.push(event.target.files[0]);
                    tmpCarousels.push(URL.createObjectURL(event.target.files[0]))
            
                    console.log("selected " + tmpCarousels.length)
            
                    setSelectedCarouselFiles(tmpFiles);
                    setSelectedCarousels(tmpCarousels);
            
                    setTimeout(function() {
                        setIsFilePicked(!isFilePicked);
                    }, 100);
                }else{
                    alert("Rasio Foto harus 1 : 1");
                }
            };
            image.src = URL.createObjectURL(file);
        }else{
            alert("Ukuran File Terlalu Besar");
        }
        
	};

	const onSubmitButtonClick = async() => {
        if(adType === 101){
            setupImageAd();
        }else if(adType === 102){
            setupVideoAd();
        }else if(adType === 103){
            setupCarouselAd();
        }
	};

    const setupImageAd = async() => {
        setIsUploading(true);
        setUploadingMessage("Mengunggah Foto...");
        
        let formData = new FormData();
        formData.append("image_file", selectedImageFile);

        let authParam = "?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("password")+"&ad_id="+params.id;
        

        try {
            const response = await axios({
                method: "post",
                url: UrlHouse.UPLOAD_IMAGE+authParam,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                
            });
            
            let decodedResponse = response.data;

            if(decodedResponse.result === 1){
                setUploadingMessage("Mengatur Komponen Iklan...");

                let imageName = decodedResponse.image_name;
    
                $.ajax({
                    type: "POST",
                    url: UrlHouse.SETUP_IMAGE_AD,
                    data: JSON.stringify({
                        email: localStorage.getItem("email"),
                        password: localStorage.getItem("password"),
                        ad_id: params.id,
                        ad_image_name: imageName,
                        ad_description: adDescription,
                        ad_link: adLink,
                        ad_cta: adAction
                    }),
                    dataType: "json",
                    success: function (response) {
                        if(response.result === 1){
                            navigate('/dashboard/ads/create/finish/'+params.id);
                        }else{
                            alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                        }
                    },
                    error: function(e){
                        alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                    }
                });
            }else{
                alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
            }
        } catch(error) {
            alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
        }
        setIsUploading(false);
        setUploadingMessage("");
    };

    const setupVideoAd = async() => {
        setIsUploading(true);
        setUploadingMessage("Mengunggah Video...");
        
        let formData = new FormData();
        formData.append("video_file", selectedVideoFile);

        let authParam = "?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("password")+"&ad_id="+params.id;
        

        try {
            const response = await axios({
                method: "post",
                url: UrlHouse.UPLOAD_VIDEO+authParam,
                data: formData,
                headers: { "Content-Type": "multipart/form-data" },
                
            });

            console.log(response);
            
            let decodedResponse = response.data;

            console.log(decodedResponse);

            if(decodedResponse.result === 1){
                setUploadingMessage("Mengatur Komponen Iklan...");

                let videoName = decodedResponse.video_name;
    
                $.ajax({
                    type: "POST",
                    url: UrlHouse.SETUP_VIDEO_AD,
                    data: JSON.stringify({
                        email: localStorage.getItem("email"),
                        password: localStorage.getItem("password"),
                        ad_id: params.id,
                        ad_video_name: videoName,
                        ad_description: adDescription,
                        ad_link: adLink,
                        ad_cta: adAction
                    }),
                    dataType: "json",
                    success: function (response) {
                        if(response.result === 1){
                            navigate('/dashboard/ads/create/finish/'+params.id);
                        }else{
                            alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                        }
                    },
                    error: function(e){
                        alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                    }
                });
            }else{
                console.warn("error uploading");
                alert("Ups! Terjadi masalah saat membuat mengunggah video, coba video lain");
            }
        } catch(error) {
            console.warn(error);
            alert("Ups! Terjadi masalah saat membuat mengunggah video, coba video lain");
        }
        setIsUploading(false);
        setUploadingMessage("");
    };

    const setupCarouselAd = async() => {
        setIsUploading(true);
        setUploadingMessage("Mengunggah Foto...");

        let uploadedImages = [];

        for(let i = 0; i < selectedCarouselFiles.length; i++){
            var image = selectedCarouselFiles[i];
            let formData = new FormData();
            formData.append("image_file", image);

            let authParam = "?email="+localStorage.getItem("email")+"&password="+localStorage.getItem("password")+"&ad_id="+params.id;

            try {
                const response = await axios({
                    method: "post",
                    url: UrlHouse.UPLOAD_IMAGE+authParam,
                    data: formData,
                    headers: { "Content-Type": "multipart/form-data" },
                    
                });
                
                let decodedResponse = response.data;
    
                if(decodedResponse.result === 1){
                    uploadedImages.push(decodedResponse.image_name);
                }else{
                    alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
                    break;
                }
            } catch(error) {
                alert("Ups! Terjadi masalah saat membuat mengunggah foto, coba foto lain");
                break;
            }
        }
        
        if(uploadedImages.length === selectedCarouselFiles.length){
            $.ajax({
                type: "POST",
                url: UrlHouse.SETUP_CAROUSEL_AD,
                data: JSON.stringify({
                    email: localStorage.getItem("email"),
                    password: localStorage.getItem("password"),
                    ad_id: params.id,
                    ad_image_name: uploadedImages,
                    ad_description: adDescription,
                    ad_link: adLink,
                    ad_cta: adAction
                }),
                dataType: "json",
                success: function (response) {
                    if(response.result === 1){
                        navigate('/dashboard/ads/create/finish/'+params.id);
                    }else{
                        alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                    }
                },
                error: function(e){
                    alert("Ups! Terjadi masalah saat membuat iklan. Silahkan coba lagi nanti");
                }
            });
        }
        
        setIsUploading(false);
        setUploadingMessage("");
    };

    const [adDescription, setDescription] = useState("");
    const [adLink, setLink] = useState("");
    const [adAction, setAction] = useState("");

    const onFormChange = (e) => {
        let target = e.target.name;
        let value = e.target.value;
        if(target === "input_description"){
            setDescription(value);
        }
        if(target === "input_link"){
            setLink(value);
        }
        if(target === "input_action"){
            setAction(value);
        }
    }

    const hiddenFileInput = React.useRef(null);

    function getAdType(){
        if(adType === 101){
            return "Iklan Foto";
        }
        if(adType === 102){
            return "Iklan Video";
        }
        if(adType === 103){
            return "Iklan Karosel";
        }
        return "Iklan Foto";
    }

    function getMediaInputButton(){
        if(adType === 102){
            return (
                <Button onClick={() => {hiddenFileInput.current.click()}}>
                    {selectedVideo != null?"Pilih Video Lain":"Pilih Video"}
                </Button>
            )
        }else if(adType === 103){
            return (
                <>
                    {selectedCarouselFiles.length < 5?<Button style={{marginRight: "10px"}} onClick={() => {hiddenFileInput.current.click()}}>
                        {selectedCarouselFiles.length > 0?"Tambahkan Foto":"Pilih Foto"}
                    </Button>:<></>}
                    {selectedCarouselFiles.length > 0?<Button style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue}}
                        onClick={() => {
                            setSelectedCarouselFiles([]);
                            setSelectedCarousels([]);
                        }}>
                        {selectedCarouselFiles.length > 1?"Hapus Semua Foto":"Hapus Foto"}
                    </Button>:<></>}
                </>
            )
        }
        return (
            <Button onClick={() => {hiddenFileInput.current.click()}}>
                {selectedImage != null?"Pilih Foto Lain":"Pilih Foto"}
            </Button>
        )
    }

    function getMediaPreview(){
        if(adType === 102){
            return (
                <>
                    <input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="video/mp4" type="file" onChange={onFileChangeVideo} />
                    {
                        selectedVideo != null?<video style={styles.imagePreviewStyle} playsInline controls>
                            <source src={selectedVideo} type="video/mp4"/>
                        </video>:<div></div>
                    }
                    <br></br>
                    <label style={{fontWeight: "300", marginTop: "5px"}}>Format Video yang dapat digunakan adalah MP4. Dengan ukuran tidak lebih dari 25MB</label>
                </>
            )
        }else if(adType === 103){
            return (
                <>
                    {selectedCarousels.length < 5?<input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="image/png, image/jpeg" type="file" onChange={onFileChangeCarousel} />:<></>}
                    {
                        getCarouselMediaPreview()
                    }
                    <br></br>
                    <label style={{fontWeight: "300", marginTop: "5px"}}>Pilih hingga 5 Foto. Format Foto yang dapat digunakan adalah JPEG, JPG, dan PNG. Dengan ukuran tidak lebih dari 10 MB dan rasio Foto 1 : 1</label>
                </>
            )
        }
        return (
            <>
                <input style={styles.selectImageButtonStyle} ref={hiddenFileInput} accept="image/png, image/jpeg" type="file" onChange={onFileChange} />
                {selectedImage != null?<img style={styles.imagePreviewStyle} src={selectedImage} alt="preview" />:<div></div>}
                <br></br>
                <label style={{fontWeight: "300", marginTop: "5px"}}>Format Foto yang dapat digunakan adalah JPEG, JPG, dan PNG. Dengan ukuran tidak lebih dari 10 MB</label>
            </>
        )
    }

    function getCarouselMediaPreview(){
        return (
            <>
                {selectedCarousels.length > 0 && selectedCarousels.map((item) => <img style={styles.imagePreviewStyle} src={item} alt="preview" />)}
            </>
            
        );
    }

    function mediaSelected(){
        if(selectedCarousels.length > 1){
            return true;
        }
        if(selectedImage != null){
            return true;
        }
        if(selectedVideo != null){
            return true;
        }
        return false;
    }

    const onBack = () => {
        navigate('/dashboard/ads')
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    isLoaded?<div style={styles.mainContainerStyle}>
                        <h1>Buat Media Iklan</h1>
                        <h4>{adName}  •  {getAdType()}</h4>
                        <hr></hr>
                        {getMediaInputButton()}
                        <div>
                            {getMediaPreview()}
                            <br></br>
                            <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Deskripsi</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_description" type="text"/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Link Tujuan</Form.Label>
                                <Form.Control onChange={onFormChange} name="input_link" type="text"/>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label as="legend" column sm={1} style={styles.businessInquiryFormRadioLabelStyle}>
                                    Tombol Aksi
                                </Form.Label>
                                <Col sm={4}>
                                    <Form.Check
                                        type="radio"
                                        label="Pelajari Selengkapnya"
                                        onChange={onFormChange}
                                        name="input_action"
                                        value="Pelajari Selengkapnya"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Kunjungi Halaman"
                                        onChange={onFormChange}
                                        name="input_action"
                                        value="Kunjungi Halaman"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Coba Sekarang"
                                        onChange={onFormChange}
                                        name="input_action"
                                        value="Coba Sekarang"
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Beli Sekarang"
                                        onChange={onFormChange}
                                        name="input_action"
                                        value="Beli Sekarang"
                                    />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={onSubmitButtonClick} disabled={!mediaSelected() || adDescription.length === 0 || adLink.length === 0 || adAction.length === 0 || isUploading} style={styles.sendButtonStyle}>Buat Iklan</Button>
                                    {isUploading?<div className="loading-spinner" style={{marginTop: "5px", marginLeft: "10px"}}></div>:<></>}
                                    {isUploading?<div style={{marginTop: "5px", marginLeft: "10px"}}>{uploadingMessage}</div>:<></>}
                                    <Button onClick={onBack} style={{backgroundColor: appContext.colors.primaryWhite, color: appContext.colors.primaryBlue, marginLeft: "10px"}}>Kembali</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                        </div>
                    </div>:<></>
                    
                }
            </div>
        </>
    )
}

export default SetupAdPage;