import React, { useEffect, useContext } from "react";
import $ from 'jquery';
import { Navbar, Nav, Button, Col } from "react-bootstrap";
import { Outlet, Link, useParams } from "react-router-dom";
import { AppContext } from "../utils/AppContext";
import { useTranslation } from "react-i18next";
import UrlHouse from "../utils/UrlHouse";
import { useNavigate } from "react-router-dom";
import WoiloLogoFullBlue from '../asset/logo_long_transparent_cropped.png';
import Logo from '../asset/logo_woilo_full.svg';
import NavbarToggle from '../asset/navbar_toggle.svg';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const DashboardPage = (props) => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const { t } = useTranslation();

    useEffect(() => {
        init();
    })

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.LOGIN,
            data: JSON.stringify({
            email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result !== 1){
                    navigate('/login');
                }
            },
            error: function(e){
                console.warn(e);
                navigate('/login');
            }
        });
    }

    const desktopStyles = {
        mainContainerStyle: {
            display: "flex",
        },

        navbarContainerStyle: {
            width: "15%",
            height: "100px",
            padding: "20px",
            display: "flex"
        },

        contentContainerStyle: {
            width: "88%",
        },

        navigationStyle: {
            width: "100%",
            textDecoration: "none",
            height: "50px",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlack
        }, 
    }

    const mobileStyles = {
        contentContainerStyle: {
            padding: "10px 5px",
            display: "flex",
            flexDirection: "column"
        },

        tosNavigationStyle: {
            textDecoration: "none",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlue
        },

        tosNavigationStyleInActive: {
            textDecoration: "none",
            fontWeight: "300",
            fontSize: "20px",
            lineHeight: "30px",
            marginTop: "10px",
            color: appContext.colors.primaryBlack
        },

        tosContentContainerStyle: {
            width: "100%"
        },

        navbarContainerStyle : {
            padding: "0 20px",
            backgroundColor: appContext.colors.primaryWhite
        },

        navbarToggleStyle: {
            borderColor: "transparent",
            borderRadius: "50%",
            padding: "0"
        },

        logoStyle: {
            width: "75px",
            margin: "10px"
        },

        navItemStyle: {
            marginLeft: "15px",
            marginRight: "15px",
            fontWeight: "500",
            paddingTop: "10px",
            paddingBottom: "10px",
            borderBottom: "2px solid transparent",
        },

        navItemStyleDownload: {
            fontWeight: "500",
            marginLeft: "15px",
            marginRight: "15px",
            borderRadius: "10px",
            marginTop: "5px",
            marginBottom: "5px",
            borderBottom: "2px solid transparent",
            backgroundColor: appContext.colors.primaryBlue
        },

        navItemLinkStyle: {
            color: appContext.colors.primaryBlack,
            textDecoration: "none"
        },

        navItemLinkStyleDownload: {
            padding: "10px",
            color: appContext.colors.primaryWhite,
        },
    }

    const logout = (e) => {
        e.preventDefault();
        confirmAlert({
            title: 'Keluar?',
            message: 'Kamu yakin ingin keluar dari akun ini?',
            buttons: [
                {
                    label: 'Keluar',
                    onClick: () => {
                        localStorage.removeItem("email")
                        localStorage.removeItem("password")
                        navigate("/login");
                        
                    }
                },
                {
                    label: 'Batalkan',
                    onClick: () => {}
                }
            ]
        });
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles;

    return(
        <>
            <div style={styles.mainContainerStyle}>
                {
                    !appContext.mobileMode ? 
                    <Nav style={styles.navbarContainerStyle}>
                        <img src={WoiloLogoFullBlue} style={{width: "100%", marginTop: "20px", marginBottom: "20px"}} alt="logo"/>
                        <Link style={styles.navigationStyle} to="ads" href="ads">Iklan Saya</Link>
                        <Link style={styles.navigationStyle} to="message_ads" href="message_ads">Kampanye Pesan</Link>
                        <Link style={styles.navigationStyle} to="balance" href="balance">Saldo Iklan</Link>
                        <Link style={styles.navigationStyle} to="account" href="account">Akun Iklan</Link>
                        <Link style={styles.navigationStyle} to="help" href="help">Bantuan</Link>
                        <Button onClick={logout.bind(this)} style={{width: "100%"}}>Keluar</Button>
                        <p style={{marginTop: "20px", color: appContext.colors.primaryGrey}}>
                            <i className="fa fa-copyright" aria-hidden="true"></i> 2022 PT Karya Digital Indo
                        </p>
                        <Link style={{textDecoration: "none", color: appContext.colors.primaryGrey}} to="../tos">
                            <p style={{marginTop: "5px"}}>
                                Kebijakan Penggunaan & Kebijakan Layanan
                            </p>
                        </Link>
                    </Nav>
                    :
                    <Navbar style={styles.navbarContainerStyle} expand="lg">
                        <Navbar.Brand style={styles.navbarStyle}>
                            <img alt="woilo" src={Logo} height="75" style={{marginRight: "10px"}} className="d-inline-block"/>
                        </Navbar.Brand>
                        <div>
                            <Navbar.Toggle style={styles.navbarToggleStyle} aria-controls="basic-navbar-nav"><img src={NavbarToggle} alt="navbarToggle"/></Navbar.Toggle>
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav" >
                            <Nav className="justify-content-center" activeKey="/">
                                <Nav.Item id="nav-home" style={styles.navItemStyle}>
                                    <Link style={styles.navItemLinkStyle} to="ads" href="ads">Iklan Saya</Link>
                                </Nav.Item>
                                <Nav.Item id="nav-home" style={styles.navItemStyle}>
                                    <Link style={styles.navItemLinkStyle} to="message_ads" href="message_ads">Kampanye Pesan</Link>
                                </Nav.Item>
                                <Nav.Item id="nav-home" style={styles.navItemStyle}>
                                    <Link style={styles.navItemLinkStyle} to="balance" href="balance">Saldo Iklan</Link>
                                </Nav.Item>
                                <Nav.Item id="nav-home" style={styles.navItemStyle}>
                                    <Link style={styles.navItemLinkStyle} to="account" href="account">Akun Iklan</Link>
                                </Nav.Item>
                                <Nav.Item id="nav-home" style={styles.navItemStyle}>
                                    <Link style={styles.navItemLinkStyle} to="help" href="help">Bantuan</Link>
                                </Nav.Item>
                                <Nav.Item id="nav-help" style={styles.navItemStyleDownload}>
                                    <Link style={styles.navItemLinkStyleDownload} id="nav-help" className="nav-link" onClick={logout.bind(this)}>Keluar</Link>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                }
                {
                    appContext.mobileMode ? 
                    <>
                        <hr></hr>
                    </>:
                    <>
                    </>
                }
                <div style={styles.contentContainerStyle}>
                    <Outlet/>
                </div>
                {
                    appContext.mobileMode?
                    <>
                        <p style={{marginTop: "20px", textAlign: "center", color: appContext.colors.primaryGrey}}>
                            <i className="fa fa-copyright" aria-hidden="true"></i> 2022 PT Karya Digital Indo
                        </p>
                        <Link style={{textDecoration: "none", color: appContext.colors.primaryGrey, textAlign: "center"}} to="../tos">
                            <p style={{marginTop: "5px"}}>
                                Kebijakan Penggunaan & Kebijakan Layanan
                            </p>
                        </Link>
                    </>:<></>
                }
            </div>
        </>
    )
}

export default DashboardPage;