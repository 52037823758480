import React, { useEffect, useState , useContext} from "react";
import { Link} from "react-router-dom";
import { Spinner , Form, Row, Col, Button} from "react-bootstrap";
import $ from 'jquery';
import { useParams, useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import UrlHouse from "../../utils/UrlHouse";
import DefaultProfile from '../../asset/default_profile.png';
import VerifiedBadge from '../../asset/verified_badge.png';
import { AppContext } from "../../utils/AppContext";
import Logo from '../../asset/logo_long_transparent_cropped.png';

import {Helmet} from "react-helmet";

const Account = () => {
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const [accountData, setAccountData] = useState({
        name: "",
        woiloUserName: "",
        email: ""
    });

    useEffect(() => {
        init();
    }, [])

    const init = () => {
        $.ajax({
            type: "POST",
            url: UrlHouse.DASHBOARD_ACCOUNT_INIT,
            data: JSON.stringify({
                email: localStorage.getItem("email"), password: localStorage.getItem("password")
            }),
            dataType: "json",
            success: function (response) {
                console.log(response);
                if(response.result === 1){
                    setAccountData({
                        name: response.advertiser_name,
                        woiloUserName: response.woilo_user_name,
                        email: response.advertiser_email
                    })
                }else{
                    navigate('/login');
                }
            },
            error: function(e){
                console.warn(e);
                navigate('/login');
            }
        });
    }

    const desktopStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },

        sectionContainerStyle: {
            display: "flex",
            paddingTop: "30px",
            paddingBottom: "30px",
        },

        sectionStyle: {
            flex: "1"
        },

        sectionTitleStyle: {
            marginLeft: "15px"
        },

        sectionSubtitleStyle: {
            marginLeft: "15px",
            marginRight: "80px",
            marginBottom: "10px"
        }
    }

    const mobileStyles = {
        mainContainerStyle: {
            width: "100%",
            padding: "30px",
        },
    }

    const styles = appContext.mobileMode ? mobileStyles : desktopStyles

    const [linkPreview, setLinkPreview] = useState({
        title: "Woilo - Photo, Video, Chat, NFTs",
        description: "Woilo - Photo, Video, Chat, NFTs",
        url: "",
        imageUrl: ""
    })

    const editPassword = (e) => {
        e.preventDefault();
        navigate('password');
    }

    return(
        <>
            <div style={styles.outerContainerStyle}>
                <Helmet>
                    <title>{linkPreview.title}</title>

                    <meta property="og:title" content={linkPreview.title} />
                    <meta property="og:description" content={linkPreview.description} />
                    <meta property="og:image" content={linkPreview.imageUrl} />
                    <meta property="og:url" content={linkPreview.url} />
                    <meta property="og:type" content="website"/>

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="woilo.com" />
                    <meta property="twitter:url" content={linkPreview.url} />
                    <meta name="twitter:title" content={linkPreview.title} />
                    <meta name="twitter:description" content={linkPreview.description} />
                    <meta name="twitter:image" content={linkPreview.imageUrl} />
                </Helmet>
                {
                    <div style={styles.mainContainerStyle}>

                        <h1>Akun Iklan Saya</h1>
                        <hr></hr>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>Nama Perusahaan/Brand</Form.Label>
                                <p style={{fontWeight: "500", fontSize: "20px"}}>{accountData.name}</p>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Akun Woilo Terhubung</Form.Label>
                                <p style={{fontWeight: "500", fontSize: "20px"}}>{accountData.woiloUserName}</p>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <p style={{fontWeight: "500", fontSize: "20px"}}>{accountData.email}</p>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <p style={{fontWeight: "500", fontSize: "20px"}}>********</p>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3">
                                <Col sm={{ span: 12}} style={{display: "flex"}}>
                                    <Button onClick={editPassword.bind(this)}>Ubah Password</Button>
                                </Col>
                            </Form.Group>
                        </Form>
                    </div>
                    
                }
            </div>
        </>
    )
}

export default Account;